var LazyIframe = (function(){
	var lazyiframes = document.querySelectorAll('lazyiframe');

	if(lazyiframes){
		var isOnRect = function(){
			for (var i=0;i<lazyiframes.length;i++){
				if(!lazyiframes[i].getAttribute('loaded')){
					if(lazyiframes[i].getBoundingClientRect().top < window.innerHeight + 200){
						var iframe = document.createElement('iframe');

						iframe.src = lazyiframes[i].getAttribute('src') || lazyiframes[i].getAttribute('data-src');
						iframe.allowfullscreen = true;
						iframe.frameborder = 0;

						lazyiframes[i].setAttribute('loaded', 'true');
						lazyiframes[i].appendChild(iframe);
					}
				}
			}
		}
		var jaIframe = false;

		isOnRect();

		window.addEventListener('scroll',function(){
			if(jaIframe) return;

			setTimeout(function(){
				jaIframe = false;
			},100);

			isOnRect();
		});
	}
})();