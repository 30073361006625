$('[data-toggle="menu-dropdown"]').click(function(event){
	event.preventDefault();

	var pai = $(this).parent();

	pai.toggleClass('open');
});

$('#menu').click(function(event){
	event.stopPropagation();
});

$(document).click(function(){
	$('.menu .open').removeClass('open');
});