$('.menu').affix({
	offset: {
		top: function(){
			return (this.top = $('.topo').outerHeight(true))
		}
	}
});

$('.menu').on('affix.bs.affix', function(){
	$('.topo').css('padding-bottom',$(this).outerHeight());
});

$('.menu').on('affix-top.bs.affix', function(){
	$('.topo').removeAttr('style');
});