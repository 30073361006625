(function() {
	var observer = new IntersectionObserver(function(entries) {
		for(var i=0;i<entries.length;i++) {
			var entry = entries[i];

			if(entry.isIntersecting) {
				var lazy = entry.target;
				var attributes = lazy.attributes;
				var attrs = '';

				for(var o=0;o<attributes.length; o++) {
					attrs += ' '+attributes[o].name.replace('data-','')+'="'+attributes[o].value+'"';// ` ${attr.name}="${attr.value}"`
				}

				lazy.insertAdjacentHTML('beforeend', '<img'+attrs+'>');

				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '200px 200px 200px 200px'
	});

	if(window.customElements) {
		class LazyImage extends HTMLElement {
			constructor(data) {
				super(data);
			}

			connectedCallback () {
				observer.observe(this);
			}

			get src () {
				if(this.hasAttribute('src')) {
					return this.getAttribute('src');
				}else {
					return this.dataset.src || '';
				}
			}

			get alt () {
				if(this.hasAttribute('alt')) {
					return this.getAttribute('alt');
				}else {
					return this.dataset.alt || '';
				}
			}
		};

		customElements.define('lazy-image', LazyImage);

		fixer();

		function fixer() {
			var irregularLAzyImage = document.querySelectorAll('lazyimage');

			for(var i=0;i<irregularLAzyImage.length;i++) {
				var lazy = irregularLAzyImage[i];
				var template = lazy.outerHTML;

				template = template.replace(/lazyimage/g, 'lazy-image');

				lazy.insertAdjacentHTML('afterend', template);
				lazy.parentNode.removeChild(lazy);
			}
			requestAnimationFrame(fixer);
		}

	}else {

		lazyImageRegister();

		function lazyImageRegister() {
			var lazyImages = document.querySelectorAll('lazy-image:not(.registred), lazyimage:not(.registred)');

			for(var i=0;i<lazyImages.length;i++) {
				var lazy = lazyImages[i];

				observer.observe(lazy);
				lazy.className = lazy.className + ' registred';
			}

			requestAnimationFrame(lazyImageRegister);
		}
	}
})();
