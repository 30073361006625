var myOwlCarousel = function (alvo,options){
	if($(alvo).children().length > 1){
		$(alvo).owlCarousel(options);
	}
	else{
		$(alvo).addClass('owl-loaded');
	}
};

myOwlCarousel('#servicos', {
	responsive: {
		0: {
			items: 1
		},
		600: {
			items: 2,
			margin: 15
		},
		992: {
			items: 3,
			margin: 15
		}
	},
	nav:true,
	navText: [
		'<i class="glyphicon glyphicon-chevron-left"></i>',
		'<i class="glyphicon glyphicon-chevron-right"></i>'
	],
	loop: true,
	autoplay: true
});