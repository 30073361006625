$('[data-toggle="menu"]').click(function(){
	var a = $(this).data('target');

	$(a).toggleClass('in');

	setTimeout(function(){
		$('.show-menu').addClass('open');
	},100);
});

$('.show-menu').click(function(){
	$('#menu').removeClass('in');
});