(function() {
	var observer = new IntersectionObserver(function(entries) {
		for(var i=0;i<entries.length;i++) {
			var entry = entries[i];

			if(entry.isIntersecting) {
				var bg = JSON.stringify(entry.target.dataset.lazyBg);

				entry.target.style.setProperty('background-image', 'url('+bg+')');
				entry.target.removeAttribute('data-lazy-bg');

				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '200px 200px 200px 200px'
	});
	var lazyBgContainer = document.querySelectorAll('[data-lazy-bg]');

	for(var i=0;i<lazyBgContainer.length;i++) {
		observer.observe(lazyBgContainer[i]);
	}
})();
